@import url('https://fonts.googleapis.com/css?family=Major+Mono+Display&display=swap');
@import url("https://fonts.googleapis.com/css?family=Major+Mono+Display|Montserrat&display=swap");

body {
  background-color: black;
  margin: 0;
  margin-top: 100px;
  /* padding-bottom: 100px; */
  overflow: hidden;
  /* height: 100% + 50px; */
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: 'Major Mono Display', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;

}

/* GLOBAL */



/* .section-title {
    font-size: 3em;
  } */

/* SCROLL-SNAPPING */

/* SCROLL BAR */

.scroll-snap-view::-webkit-scrollbar {
  width: 9px;
  height: 7px;
}

.scroll-snap-view::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 2px;
}

.scroll-snap-view::-webkit-scrollbar-track {
  background: #262626;
}

/* SCROLL BUTTONS */

/* Buttons */
::-webkit-scrollbar-button:single-button {
  background-color: #ffffff;
  display: block;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  /* border-style: solid; */
  height: 12px;
  width: 15px;
}

/* Up */
.scroll-snap-view::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 10px 10px 10px;

  border-color: transparent transparent #262626 transparent;
}

.scroll-snap-view::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #bbbbbb transparent;
}

/* Down */
.scroll-snap-view::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 10px 10px 0 10px;
  border-color: #262626 transparent transparent transparent;
}

.scroll-snap-view::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #bbbbbb transparent transparent transparent;
}

.scroll-snap-view {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  /* For some reason, overflow: scroll doesn't detect that the view is shorter than itself.  */
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-points-y: repeat(100vh);

  height: 100vh;

}

.scroll-snap-view>.scroll-snap-child {
  scroll-snap-align: center;
}

/* GLOBAL COMPONENTS */
/* BUTTON */

.button {
  margin: 0;
  position: absolute;
  top: auto;
  bottom: 10vh;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

}

.button-contact {
  margin: 0;
  position: absolute;
  top: auto;
  bottom: 25vh;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

}

.button-top {
  position: relative;
  margin-top: 3rem;
  /* width: 120px; */
  left: 0;
  top: 0;
  padding: 18px 20px 16px 20px;
  /* transition: all .1s ease-in-out; */
}

.button-label {
  font-family: 'Major Mono Display', monospace;
  font-weight: 800;
  font-size: 12px;
  line-height: 110%;
  letter-spacing: 2px;
  text-align: center;
  /* transition: all .1s ease-out; */
}

/* ABOUT */

.skill-slide {
  display: grid;
  grid-template-columns: auto auto auto;
}

.testimonialPhoto {
  width: 10vh;
  height: 10vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #ffb619;
  border-radius: 50%;
  top: 5%;
  position: absolute;
}

.testimonialContributor {
  position: absolute;
  top: 19%;
  /* text-shadow: 3px 3px 2px #131313; */
  background-color: #13131385;
  border-bottom: 1px solid #ffb619;
}

.testimonialRelationship {
  position: absolute;
  top: 23%;
  text-shadow: 3px 3px 2px #131313;
  color: #ffb619;
  font-size: small;
  /* background-color: #1313134e; */
  /* border-bottom: 1px solid #ffb619; */
}

.testimonialMessage {
  font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Garuda, Verdana, Tahoma, sans-serif;
  max-height: 50%;
  overflow: auto;
  width: 50%;
  margin-top: 15%;
  text-overflow: ellipsis;
  text-align: justify;
  white-space: pre-wrap;
  background-color: #262626;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  /* border: 1px dotted #bbbbbb; */
  border-radius: 2px;
  border-top: 1px solid gray;
  border-bottom: 1px solid #ffb619;
}

.testimonialMessage::-webkit-scrollbar {

  width: 2px;
  height: 2px;
  left: 5px;
}

.testimonialMessage::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 2px;
}

.testimonialMessage::-webkit-scrollbar-track {
  background: #262626;
}

.about-button-border-active {
  width: 60%;
  height: 1px;
  position: absolute;
  background-color: #ffb619;
  opacity: 1;
  transition: all .25s ease-out;
  left: 50%;
  transform: translateX(-50%);
}

.about-button-border-disabled {
  width: 0%;
  position: absolute;
  background-color: #ffffff;
  opacity: 0;
  /* transition: all .25s ease-out; */
  left: 50%;
  transform: translateX(-50%);
}

.about-section-content-grid {
  /* width: 90vw; */
  max-height: 80vh;
  /* transition: all .1s ease-in-out; */
  overflow-y: auto;
  display: grid;
  /* grid-template-areas: 'left right'; */
  /* grid-template-columns: 4fr 4fr; */

  grid-template-columns: repeat(auto-fit, minmax(95%, 2fr));
  /* grid-template-rows: repeat(2, auto); */
  grid-auto-flow: row;
  grid-gap: 1;
}

.about-image-carousel-column {
  /* grid-area: left; */
  grid-row: auto;

}

.about-skills-column {
  /* grid-area: right; */
  grid-row: auto;
  margin: 0;
  position: absolute;
  top: 60%;
  width: 100%
    /* -ms-transform: translateY(-50%); */
    /* transform: translateY(-50%); */

}

/* ABOUT - CAROUSEL SECTION */
/* Wrapper */
.about-image-carousel {
  width: 100%;
  height: 100%;
  /* background-color: #161616; */
  /* opacity: .8; */
  margin-bottom: 10px;
  margin: 0;
  position: absolute;
  top: 20%;
  /* -ms-transform: translateY(-50%); */
  /* transform: translateY(-50%); */

}

.about-image-carousel-invisible {
  transform: translateY(10px);

  opacity: 0;


}

/* CAROUSEL STUFF */

.slider {
  width: 90vw;
  text-align: center;
  overflow: hidden;
}

.slides {
  /* position: sticky; */
  top: 25vh;
  display: flex;
  height: 50vh;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

}

.slides::-webkit-scrollbar {

  width: 7px;
  height: 7px;
}

.slides::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 2px;
}

.slides::-webkit-scrollbar-track {
  background: #262626;
}

.slides>div {
  scroll-snap-align: center;
  flex-shrink: 0;
  width: 80%;
  height: 100%;
  margin-right: 10px;
  background: #161616;
  transform-origin: center center;
  transform: scale(1);
  /* transition: transform 0.5s; */
  /* position: relative; */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: .5px solid gray;
  border-style: groove;

}


.carousel-img {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ABOUT - SKILL SECTION  */
.skill-name {
  font-family: 'Montserrat', sans-serif;
  width: 80px;
  font-size: 10px;
  text-shadow: 1px 1px 1px gray;
  grid-area: left;
  grid-row: auto;
  transform: translateY(-70%);

}

.skillSectionMasterWrapper {
  /* display: grid; */
  /* grid-template-columns: auto auto; */
  /* height: 60vh; */
  /* width: 100%; */
  /* overflow: auto; */
  /* position: absolute; */
  max-width: 1000px;
  max-height: 59vh;
  top: 150px;
  /* position: absolute; */
  margin-left: auto;
  margin-right: auto;
  left: auto;
  right: auto;
  /* left: 50%; */
  z-index: 100;

}

.skillMasterWrapper::-webkit-scrollbar {
  width: 4px;
  height: 7px;
}

.skillMasterWrapper::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 2px;
}

.skillMasterWrapper::-webkit-scrollbar-track {
  background: #262626;
}

.skillMasterWrapper {
  color: white;
  /* grid-column: auto; */
  /* width: 50%; */
  /* display: grid; */
  /* width: 50%; */
  position: absolute;
  width: 100%;
  left: 0;
  top: 13%;
  overflow: auto;
  max-height: 67%;
  /* position: absolute; */
  /* position: relative; */
  /* top: 10px; */

}

.skillMessage {
  font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Garuda, Verdana, Tahoma, sans-serif;
  /* max-height: 50%; */
  /* overflow: auto; */
  /* width: 100%; */
  /* top: 15%; */
  /* position: absolute; */
  /* margin-top: 15%; */
  text-overflow: ellipsis;
  text-align: justify;
  white-space: pre-wrap;
  background-color: #262626;

  border-radius: 2px;
  border-top: 1px solid gray;
  border-bottom: 1px solid #ffb619;
}

.skillPillWrapper {

  background-color: #262626;
  /* padding: 10px; */
  font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Garuda, Verdana, Tahoma, sans-serif;
  /* position: absolute;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); */
  /* grid-auto-rows: max-content; */
  /* grid-auto-columns: max-content; */
  /* flex-wrap: wrap; */
  /* width: 50%; */
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* top: 25%; */
  /* width: 50%; */
  /* height: 50%; */
  text-align: center;
  align-items: center;
  /* max-height: 100px; */
  /* overflow-y: none;
  overflow-x: hidden; */
  /* grid-template-columns: auto auto auto; */
  display: flex;
  flex-wrap: wrap;
  /* justify-content: flex-start; */
  /* align-content: center; */
  border-radius: 5px;
  /* margin-top: -10%; */
  /* top: 25%; */

  /* position: absolute; */
}


.skill-slide-skills {
  /* grid-column: right;
  width: 50%; */
  /* grid-area: right; */
  /* top: 50%; */
  /* display: flexbox; */
  /* position: relative; */
  top: 0;
  position: relative;
}

.skillPill {
  background-color: #131313;
  border-radius: 5px;
  padding: 10px;
  margin: 15px;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid gray;
  border-bottom: 1px solid #ffb619;
  /* width: 50%; */
  float: left;
  min-width: 80px;
}

.skill-title {
  /* position: absolute; */
  font-family: 'Major Mono Display', monospace;
  /* width: 100%; */
  line-height: 1.5;

  /* left: 50%; */
  /* top: 0; */
  /* transform: translatex(-50%); */
  /* margin-top: 10px; */
  text-shadow: 2px 2px 2px black;
  /* font-weight: 900; */
  /* font-size: large; */
}

.skill-name-wrapper {
  height: 16px;
}


.skill-bar {

  grid-area: right;
  grid-row: auto;
  height: 60%;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  transition: all 1s ease-in-out;
  box-shadow: 2px 0px 0px black;
  opacity: .7;

}


.skill-slider-wrapper {
  min-width: 50vw;
  display: grid;
  grid-template-areas: 'left right';
  grid-template-columns: 1fr 5fr;
  grid-auto-flow: row;
  grid-gap: 0;
  max-width: 100%;
  border: .5px solid gray;
  margin: 0.1px;
  border-style: groove;
  /* margin: 0;
  position: absolute; */
  /* position: sticky; */
}

.invisible-skill-wrapper {
  opacity: 0;
  transform: translateX(-20%);

}


/* SECTION COMPONENT */
.section-children-wrapper {
  left: 50%;
  transform: translateX(-50%);
  /* transition: all .15s ease-in-out; */
  min-width: 90%;
  max-width: 90%;
  max-height: 100%;
  height: 100vh;
  z-index: 2;
  position: absolute;
  text-align: center;
  margin: 0;

}

.section-title {
  margin-top: 2%;
  /* margin-bottom: 30px; */
  font-weight: 900;
  font-size: 5.5vh;
}


/* CONTACT SECTION  */

.social-icon-image {
  width: 30px;
  color: 'white';
}

.social-icon-image:hover {
  width: 30px;
  color: 'blue';
}

.social-icon-image-bottom {
  width: 20px;
  color: 'white';
}

.social-icon-color {
  fill: 'white'
}

.social-button-selection {
  margin-top: 10px;
  bottom: 0
}



/* WELCOME SECTION */

.erikProfileCard {
  /* position: relative; */
  /* display: grid;
  grid-column: auto;*/
  margin: 0 auto;
  /* display: ; */
  /* left: -50%; */
  color: white;
  /* width: 100%; */
  position: relative;
  width: 30%;
  /* grid-row: auto; */
  top: 10%;
}

/* ,
marginLeft: "auto",
marginRight: "auto",
position: "relative" */
.welcomePhoto {
  width: 20vh;
  height: 20vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #262626;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  top: 10px;
  position: relative;
  border: 1px solid #42f5d1;
}

/* marginLeft: "auto",
marginRight: "auto",
position: "relative"
marginLeft: "auto",
marginRight: "auto",
position: "relative" */
.welcomeContributor {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  top: -30px;
  text-shadow: 3px 3px 2px #131313;
  background-color: #1313134e;
  border-bottom: 1px solid #42f5d1;
  font-size: larger;
}

.welcomeRelationship {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  top: -50px;
  text-shadow: 3px 3px 2px #131313;
  color: #42f5d1;
  font-size: 15px;
  width: 100%;
  /* background-color: #1313134e; */
  /* border-bottom: 1px solid #ffb619; */
}

.welcome {
  margin-top: 20px;
}

.profile-picture {
  position: absolute;
  border-radius: 50%;
  border: 2px solid #42f5d1;
}

.profile-picture:hover {
  position: absolute;
  filter: contrast(1.20)
}

.background-profile {
  filter: blur(4px)
}

.foreground-profile:hover {
  text-shadow: 2px 2px 2px #131313;
}

.welcome-statement {
  font-size: 3vw;
  max-width: 80vw;
  height: 10vh;
  left: 7%;
  /* transform: translateX(-50%); */
  margin-top: 30vh;
  margin: 0;
  position: absolute;
  top: 60%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.section-title,
.welcome-statement {
  font-family: 'Major Mono Display', monospace;
  /* transition: all .15s ease-in-out; */

}

/* PORTFOLIO SECTION */

.button-selection-grid {
  font-family: 'Major Mono Display', monospace;
  position: relative;
  color: white;
  /* transition: all .15s ease-in-out; */
  /* width: 80%; */
  line-height: 1.5;
  /* font-weight: 400; */
  font-size: small;
  text-align: center;
  /* top: -50%; */
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-shadow: 2px 2px 2px black;
  min-width: 50vw;
  display: grid;
  grid-template-areas: 'left right';
  grid-template-columns: 5fr 5fr;
  grid-auto-flow: row;
  grid-gap: 0;
  max-width: 100%;
  /* margin-bottom: 20px; */
  padding-top: 5%;
  /* height: 50px; */
}

.music-portfolio-button .section-title,
.software-portfolio-button .section-title {
  grid-row: auto;
  /* transition: all .15s ease-in-out; */
  font-size: 2em;
}

.software-portfolio-button {
  grid-area: left;
  cursor: pointer;
  /* opacity: .8 */
}

.music-portfolio-button {
  grid-area: right;
  cursor: pointer;
  /* opacity: .8; */

}

.music-portfolio-button:hover,
.software-portfolio-button:hover {
  background-color: #262626;
}





.project-content-grid,
.project-content-grid-hidden {
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  /* transition: all .15s ease-in-out; */
  width: 80%;
  line-height: 1.5;
  font-weight: 400;
  font-size: small;
  top: 25vh;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-shadow: 2px 2px 2px black;
  /* min-width: 50vw; */
  display: grid;
  grid-template-areas: 'left right';
  grid-template-columns: 5fr 5fr;
  grid-auto-flow: row;
  grid-gap: 0;
  max-width: 100%;
  margin: 1px;

}



.project-content-grid-hidden {
  opacity: 0;

}

.project-content-grid {
  opacity: 1;
  overflow: auto;
  height: 200px;
}

.project-media {
  grid-area: left;
  width: 100%;
  /* height: 166px; */
  grid-row: auto;
  position: absolute;
  /* top: 25%; */
  -ms-transform: translateY(-50%);
  transform: translate(-25%, 20%);
}

.project-media-github {
  grid-area: left;
  width: 100%;
  /* height: 166px; */
  grid-row: auto;
  position: absolute;
  /* top: 25%; */
  /* -ms-transform: translateY(-50%); */
  /* transform: translate(-25%, 20%); */
}

.project-description-column::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

.project-description-column::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 2px;
}

.project-description-column::-webkit-scrollbar-track {

  background: #262626;
}

.project-description-column {
  grid-area: right;
  grid-row: auto;
  max-height: 30vh;
  /* transition: all .5s ease-in-out; */
  overflow-y: auto;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
}

.description-border-left {
  transform: translateX(-5px);
  height: 100%;
  background-color: #ff4242;
  width: 1px;
  position: absolute;
}

.button-border-active {
  width: 60%;
  height: 1px;
  position: absolute;
  background-color: #ff4242;
  opacity: 1;
  transition: all .25s ease-out;
  left: 50%;
  transform: translateX(-50%);
}

.button-border-disabled {
  width: 0%;
  position: absolute;
  background-color: #ffffff;
  opacity: 0;
  /* transition: all .25s ease-out; */
  left: 50%;
  transform: translateX(-50%);
}



.media-player {
  font-size: 10px;
  color: #262626;
  line-break: anywhere;
  word-break: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Garuda, Verdana, Tahoma, sans-serif;
  font-weight: 100;

}

.github-media {
  height: 166px;
  width: 100%;
  background-color: gray;
  /* opacity: .8; */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}



.project-name {
  position: absolute;
  font-family: 'Major Mono Display', monospace;
  width: 100%;
  line-height: 1.5;
  left: 50%;
  transform: translatex(-50%);
  /* margin-top: 10px; */
  text-shadow: 2px 2px 2px black;
  /* font-weight: 900; */
  font-size: large;
  /* letter-spacing: 2px; */


}

.project-github-name {
  position: absolute;
  font-family: 'Major Mono Display', monospace;
  width: 100%;
  line-height: 1.5;
  top: 0;
  /* left: 50%; */
  transform: translatex(-50%);
  /* margin-top: 10px; */
  text-shadow: 2px 2px 2px black;
  /* font-weight: 900; */
  /* font-size: large; */
  /* letter-spacing: 2px; */
}

/* Language Display */
.languageGraph {
  background-color: #262626;
  border-radius: 5px;
}

.languagePercentage {
  top: 0;
  height: 100%;
  display: "block";
  /* border: 1px solid black; */
}

.languagePercentageBar {
  display: flex;
  align-items: stretch;
  border: 1px solid gray;
  overflow: hidden;
  width: 90%;
  height: 5px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
}

.languageList {
  display: flex;
  grid-template-columns: auto auto auto;
  font-size: 10px;
  text-align: center;
}

.languageItem {
  margin-left: auto;
  margin-right: auto;
  /* box-shadow: white; */
}

/* CAROUSEL */

.carousel-button {
  color: white;
  /* display: inline; */
  position: absolute;
  top: 16vh;
  font-size: 5em;
  z-index: 10;
  cursor: pointer;
  /* transform: translateY(-44vh); */
  /* vertical-align: middle; */
  text-shadow: 2px 2px 2px #161616;
  /* transition: ease-in-out .1s; */
  /* width: 50px; */
  /* height: 10px; */

}

.carousel-left-button {
  left: 10px;
  opacity: .6;
  float: left;
}

.carousel-right-button {
  right: 10px;
  opacity: .6;
  float: right
}

.carousel-left-button:hover,
.carousel-right-button:hover {

  text-shadow: 3px 3px 5px black;
  opacity: 1;
}

.button-project {
  /* bottom: 0; */
  top: 43vh;


}

@media only screen and (max-height: 570px) {
  .slides {
    top: 100px;
    position: absolute;
    width: 90vw;
    /* height: 50vh; */
  }

  .project-media,
  .github-media {
    height: 30vh;
  }

  .project-description-column {
    max-height: 30vh;
  }

  .project-name {
    font-size: 1em
  }

  .project {
    height: 48vh !important;
  }

  .skill-name {
    display: none;

  }

  .skill-title {
    font-size: 10px;
  }

  .skill-name-wrapper {
    display: none;
  }


  .skill-bar {

    display: none;

  }


  .skill-slider-wrapper {
    display: none;
  }

  .invisible-skill-wrapper {
    display: none;

  }
}


@media only screen and (max-width: 700px),
screen and (max-height: 570px) {
  .button-selection-grid {
    margin: 0;
    padding-top: 0;
    height: 20px;
  }

  .music-portfolio-button,
  .software-portfolio-button {
    height: 20px;
  }


  .music-portfolio-button .section-title,
  .software-portfolio-button .section-title {
    font-size: 1em;
    height: 20px;
    transform: translateY(-150%);

  }

  .carousel-button {
    font-size: 4em;
    /* transform: translateY(-150px); */
  }

  .carousel-left-button {
    left: 70px;
    opacity: 1;

  }

  .carousel-right-button {
    right: 70px;
    opacity: 1;

  }


  .skill-name {
    font-size: 8px;
    transform: translateY(-80%);
  }

  .skill-title {
    font-size: 15px;
  }

  .skill-name-wrapper {
    height: 10px;
  }



  .welcome-statement {
    font-size: 5vw;
  }

  .button-top {
    padding: 16px 18px 14px 18px;
  }

  .button-label {

    font-weight: 600;
    font-size: 10px;
    line-height: 110%;
    letter-spacing: 1px;
    text-align: center;
    transition: all .15s ease-out;
  }
}

@media only screen and (max-height: 650px) {
  .carousel-button {
    font-size: 3em;
    transform: translateY(-100px);
  }

  .welcome-statement {
    font-weight: 900;
    font-size: 4vw;
  }

  .button-top {
    padding: 2vh 2vw 2vh 2vw;
  }
}

@media only screen and (max-height: 420px) {

  .about-section-content-grid {
    margin-top: -20px;
  }

  .button-top {
    padding: 14px 16px 12px 16px;
  }

  .button-label {

    font-weight: 400;
    font-size: 8px;
    line-height: 100%;
    letter-spacing: 1px;
  }
}



@media only screen and (max-height: 420px) {

  .about-section-content-grid {
    margin-top: -20px;
  }

  .button-top {
    padding: 14px 16px 12px 16px;
  }

  .button-label {

    font-weight: 1000;
    font-size: 8px;
    line-height: 100%;
    letter-spacing: 2px;
  }
}


@media only screen and (max-width: 500px),
screen and (max-height: 410px) {

  .section-title,
  .welcome-statement {
    text-shadow: 2px 2px 2px black;
  }



}

@media only screen and (max-height: 406px) {

  .button-top {
    padding: .5vh 1vw .5vh 1vw;
  }


}

/* FORMS */

::placeholder {
  color: #ffffffb7;
  opacity: 1;
  font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Garuda, Verdana, Tahoma, sans-serif;
  /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #ffffffb7;
  font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Garuda, Verdana, Tahoma, sans-serif;
}

.contactForm {
  display: grid;
  grid-template-columns: auto;
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  background-color: #161616;
  padding: 20px;
  margin-top: 20vh;
  padding-bottom: 100px;
  border: .5px solid gray;
}

input,
textarea {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  /* top: 20vh; */
  background-color: #131313;
  color: white;
  font-size: large;
  margin-left: 2vw;
  margin-right: 2vw;
  border-bottom: 1px solid #80ffa2;
  border-left: 1px solid #80ffa2;
  min-height: 30px;
}

label {
  display: flex;
  position: relative;
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* top: 20vh; */
  left: 2vw;
  /* background-color: #131313; */
  color: white;
  font-size: large;
  /* margin: 10px; */
  margin-top: 20px;

  font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Garuda, Verdana, Tahoma, sans-serif;
  padding: 1px;
  /* bottom: -3px; */
}

/* CONTACT SECTION */

.contact-statement {
  /* align-self: center; */
  font-size: 1.5rem;
  /* max-width: 80vw; */
  /* height: 10vh; */
  /* left: 7%; */
  margin-right: auto;
  margin-left: auto;
  /* transform: translateX(50%); */
  /* margin-top: 30vh; */
  /* margin: 0; */
  position: absolute;
  top: 20vh;
  width: 100%;
  /* -ms-transform: translateY(
    -50%); */
  /* transform: translateY(-50%); */
}